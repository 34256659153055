header {
  width: 90vw;
  margin: 1em 0;
  display: flex;
  justify-content: space-between;
  max-width: 52em;
}

#nav-title {
  font-family: "Great Vibes";
  font-size: 1.4em;
  color: #000;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5em;
  align-items: flex-end;
}

nav li {
  margin: 0 0.4em;
}

header a {
  color: #444;
  text-decoration: none;
}

header a.active {
  color: #000;
  font-weight: 700;
  font-size: 1.2em;
}
