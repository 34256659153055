.item-list_category {
  list-style-type: none;
  padding: 0;
  margin-bottom: 2em;
}

.item-list_item {
  margin-bottom: 1em;
}

.item-list_item-title-button {
  border: 0;
  background: none;
}

.item-list_item-title-button span {
  margin-left: 0.2em;
  font-weight: 800;
  font-size: 1.2em;
  font-family: "Times New Roman", Times, serif;
}

.item-list_item-image {
  width: 6em;
  height: 100%;
  cursor: pointer;
}

.item-list_item-extended {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-top: 1em;
}

.item-list_item-description {
  margin-top: 0;
  font-family: "Times New Roman", Times, serif;
  font-weight: 500;
}

.item-list_reservation-button {
  background-color: #84917f;
  padding: 0.3em 0.5em;
  border: 1px #111 solid;
  font-weight: 700;
  font-size: 1em;
}

.mock-image {
  height: 100px;
  width: 100px;
  background-color: black;
}
