#venue-winter {
  margin-top: 1em;
}

#cityhall {
  max-height: 30em;
}

#rsvp-button-home {
  margin-top: 3em;
  margin-bottom: 10%;
}

#welcome-message {
  font-weight: 900;
  font-size: 1.3em;
  text-align: center;
}

#when-where-info {
  display: flex;
  justify-content: space-around;
  column-gap: 1em;
}
