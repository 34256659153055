#songrequest-form {
  border: 1px #576357 solid;
  padding: 0 10px;
  display: inline-block;
  margin-bottom: 1em;
}

#swiper img {
  height: 20em;
}
