.dialog_overlay {
  background-color: rgba(2, 2, 2, 0.5);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.dialog_content {
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.dialog_content:focus {
  outline: none;
}

.dialog_title {
  margin: 0;
  font-weight: 500;
  /* color: var(--mauve-12); */
  font-size: 17px;
}

.dialog_description {
  margin: 10px 0 20px;
  /* color: var(--mauve-11); */
  font-size: 15px;
  line-height: 1.5;
}

.dialog_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
}
/* .Button.violet {
	background-color: white;
	color: var(--violet-11);
	box-shadow: 0 2px 10px var(--black-a7);
}
.Button.violet:hover {
	background-color: var(--mauve-3);
}
.Button.violet:focus {
	box-shadow: 0 0 0 2px black;
}
.Button.green {
	background-color: var(--green-4);
	color: var(--green-11);
}
.Button.green:hover {
	background-color: var(--green-5);
}
.Button.green:focus {
	box-shadow: 0 0 0 2px var(--green-7);
} */

.dialog_icon-button {
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* color: var(--violet-11); */
  position: absolute;
  top: 10px;
  right: 10px;
}

.dialog_icon-button:hover {
  /* background-color: var(--violet-4); */
}

.dialog_icon-button:focus {
  /* box-shadow: 0 0 0 2px var(--violet-7); */
}

.dialog_button-container {
  display: flex;
  /* margin-top: ; */
}

.dialog_button-container.one-button {
  justify-content: flex-end;
}

.dialog_button-container.multiple-buttons {
  justify-content: space-around;
}

.dialog_button-inner-container button {
  margin-left: 1em;
}

.dialog_button-inner-container button:not(:nth-child(1)) {
  margin-left: 1em;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
