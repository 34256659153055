body {
  background-color: #576357;
  background-image: url("C:\Users\juliu\development\projects\Wedding\client\src\assets\background-2.jpg");
  background-attachment: fixed;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  /* height: 110vh; */
  background-repeat: no-repeat;
}

#main-wrapper {
  /* overflow-y: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5em;
}

#content-wrapper {
  /* height: 80vh; */
  width: 80vw;
  max-width: 50em;
}

label {
  display: block;
}

label > p {
  margin-bottom: 0.2em;
}

.one-line > * {
  display: inline;
}

.no-margin-bottom {
  margin-bottom: 0.1em;
}

form > button {
  margin: 0.8em 0;
}

h1,
h2 {
  font-family: "Great Vibes";
  font-weight: 500;
}

p,
a,
span,
button,
input,
textarea {
  font-family: "Cormorant Infant", serif;
  font-weight: 600;
}

h3 {
  font-family: "Cormorant Infant", serif;
  font-weight: 900;
}

p {
  max-width: 30em;
}

img {
  max-width: 100%;
  height: auto;
  width: auto\9;
}

.inline-50 {
  display: flex;
  justify-content: space-between;
  column-gap: 1em;
}

.vertical {
  flex-direction: column;
}

.rsvp-button_container {
  display: flex;
  justify-content: center;
}

.rsvp-button {
  text-decoration: none;
  color: #000;
  font-size: 1.2em;
  font-weight: 700;
}

.rsvp-button div {
  background-color: #84917f;
  padding: 0.6em 1.2em;
  border: 1px #111 solid;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}
